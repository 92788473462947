import React, { Component, useEffect, useContext, useState } from "react"
import { Popover, Position } from "@blueprintjs/core"
import httpClient from "_v2/Core/Utils/httpClient"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useQuery } from "react-query"
import moment from "moment"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import { locationPropTypes, organizationPropTypes } from "consts/prop-types"
import get from "lodash/get"
import createUrl from "utils/createUrl"
import { hidePartnerProgramPage, hideAppAwarePage } from "utils/auth"
import { useResource } from "_Infinity/Core/Hooks/useResource"
import { UPDATE_SUBSCRIPTIONS } from "action-types/subscriptions"
import { SessionContext } from "_v2/Modules/Auth"
import { useDistributorPlatform } from "_Infinity/DistributorPlatform/Hooks/useDistributorPlatform"
import { isDataExportVisible } from "utils/dataExport"
import { isUniversalListsVisible } from "utils/universalLists"
import { usePartnerProgram } from "components/PartnerProgramContext"
import { isDistributorSubscriptionPageVisible } from "utils/distributor"
import { isPageMspDashboard } from "utils/isPageMspDashboard"
import Tooltip from "components/Tooltip"
import {
  MdLanguage,
  MdOutlineLibraryBooks,
  MdDeviceHub,
  MdBarChart,
  MdOutlineBusiness,
  MdOutlineBuild,
  MdMiscellaneousServices,
  MdInfoOutline,
  MdOutlineOpenInNew,
} from "react-icons/md"
import cn from "classnames"
import { Sidebar } from "uiRefresh"
import { Transition } from "@headlessui/react"
import PartnerPortalIcon from "./SvgIcons/PartnerPortalIcon"
import { NAVBAR_HEIGHT } from "components/NewAppContainer"
import Badge from "./Badge"

const PartnerProgramWidget = (props) => {
  const partnerProgram = usePartnerProgram()

  return (
    (props.showPartnerProgramPage || partnerProgram.showPartnerProgram) && (
      <li className={Sidebar.getClassNameForWidgetListItem()}>
        <Link
          className={Sidebar.getClassNameForAccessPartnerPortalButton()}
          to={createUrl(`/settings/billing-info/partner-program`)}
        >
          <span
            className={Sidebar.getClassNameForAccessPartnerPortalButtonText()}
          >
            Become an MSP Partner
          </span>
        </Link>
      </li>
    )
  )
}
const FeatureTrialPopover = (props) => {
  const modifiers = {
    preventOverflow: {
      enabled: true,
      boundariesElement: "window",
    },
    flip: {
      enabled: false,
    },
  }
  return (
    <div className="h-5">
      <Popover
        position={Position.RIGHT}
        interactionKind="hover"
        modifiers={modifiers}
        content={
          <div className="px-4 py-2 text-body text-black">
            {props.featureTrials.map(
              (trial) =>
                trial.active && (
                  <div className="my-2">
                    <span className="font-bold">{trial.entitlement_name}</span>{" "}
                    ends on{" "}
                    {new Date(trial.end).toLocaleDateString("en-us", {
                      month: "long",
                      day: "numeric",
                    })}
                  </div>
                ),
            )}
          </div>
        }
      >
        <MdInfoOutline style={{ height: "20px", width: "20px" }} />
      </Popover>
    </div>
  )
}
class Navigation extends Component {
  getMenuItems() {
    const { currentOrg, session, mainOrganization, location, flags } =
      this.props
    const hideLegacyReports = !!currentOrg?.feature_flags?.includes(
      "disable_legacy_reports",
    )
    const showDataExport = isDataExportVisible(
      session,
      location?.pathname,
      flags,
    )
    const showUniversalLists = isUniversalListsVisible(
      session,
      location?.pathname,
    )
    const showSubscriptionsPage = isDistributorSubscriptionPageVisible(
      session,
      location?.pathname,
      flags,
    )

    const showAdvFilteringLog =
      session.activeOrganization?.advancedFilteringEnabled &&
      flags.showAdvancedFilteringLog

    return [
      {
        key: "overview",
        label: "Overview",
        path: createUrl("/overview/general"),
        icon: (
          <MdLanguage
            className={cn(
              "site-menu-icon",
              Sidebar.getClassNameForMenuItemLinkIcon(),
            )}
          />
        ),
      },
      ...(!localStorage.getItem("owned_msp_id")
        ? [
            {
              key: "policies",
              label: "Policies",
              path: createUrl("/policies/filtering"),
              icon: (
                <MdOutlineLibraryBooks
                  className={cn(
                    "site-menu-icon",
                    Sidebar.getClassNameForMenuItemLinkIcon(),
                  )}
                />
              ),
              children: [
                {
                  key: "filtering",
                  label: "Filtering",
                  path: createUrl("/policies/filtering"),
                },
                {
                  key: "filtering-schedules",
                  label: "Filtering Schedules",
                  path: createUrl("/policies/schedules"),
                },
                {
                  key: "universal-lists",
                  label: "Universal Lists",
                  path: createUrl("/policies/universal-lists"),
                  hidden: !showUniversalLists,
                },
                {
                  key: "block-pages",
                  label: "Block Pages",
                  path: createUrl("/policies/block-pages"),
                },
              ],
            },
          ]
        : [
            {
              key: "global-policies",
              label: "Global Policies",
              path: createUrl("/global-policies/filtering"),
              icon: (
                <MdOutlineLibraryBooks
                  className={cn(
                    "site-menu-icon",
                    Sidebar.getClassNameForMenuItemLinkIcon(),
                  )}
                />
              ),
              children: [
                {
                  key: "global-filtering",
                  label: "Filtering",
                  path: createUrl("/global-policies/filtering"),
                },
                {
                  key: "global-universal-lists",
                  label: "Universal Lists",
                  path: createUrl("/global-policies/universal-lists"),
                  hidden: !showUniversalLists,
                },
                {
                  key: "global-block-pages",
                  label: "Block Pages",
                  path: createUrl("/global-policies/block-pages"),
                },
              ],
            },
          ]),

      ...(!localStorage.getItem("owned_msp_id")
        ? [
            {
              key: "deployments",
              label: "Deployments",
              path: createUrl("/deployments/sites"),
              icon: (
                <MdDeviceHub
                  className={cn(
                    "site-menu-icon",
                    Sidebar.getClassNameForMenuItemLinkIcon(),
                  )}
                />
              ),
              children: [
                {
                  key: "sites",
                  label: "Sites",
                  path: createUrl("/deployments/sites"),
                },
                {
                  key: "user-agents",
                  label: "Roaming Clients",
                  path: createUrl("/deployments/user-agents"),
                },
                {
                  key: "collections",
                  label: "Collections",
                  path: createUrl("/deployments/collections"),
                  hidden:
                    !this.props.showActiveDirectory ||
                    localStorage.getItem("gdpr") ||
                    (localStorage.getItem("organization.isMSPSubAccount") &&
                      session?.user?.acl.deploymentsCollections === false),
                },
                {
                  key: "rc-users",
                  label: "Users",
                  path: createUrl("/deployments/users"),
                  hidden: localStorage.getItem("gdpr"),
                },
                {
                  key: "sync-tools",
                  label: "Sync Tools",
                  path: createUrl("/deployments/sync-tools"),
                  hidden:
                    !this.props.showActiveDirectory ||
                    localStorage.getItem("gdpr") ||
                    (localStorage.getItem("organization.isMSPSubAccount") &&
                      session?.user?.acl.deploymentsSincTools === false),
                },
                {
                  key: "lan-proxies",
                  label: "Relays",
                  path: createUrl("/deployments/lan-proxy"),
                  hidden:
                    localStorage.getItem("organization.isMSPSubAccount") &&
                    session?.user?.acl.deploymentsRelays === false,
                },
              ],
            },
          ]
        : []),
      {
        key: "reporting",
        label: "Reporting",
        path: createUrl("/reporting/insights"),
        icon: (
          <MdBarChart
            className={cn(
              "site-menu-icon",
              Sidebar.getClassNameForMenuItemLinkIcon(),
            )}
          />
        ),
        children: [
          {
            key: "insights",
            label: flags.enableAdvancedFilteringInsights
              ? "DNS Insights"
              : "Insights",
            path: createUrl("/reporting/insights"),
          },
          {
            key: "advanced-filtering-insights",
            label: "Adv. Filtering Insights",
            path: createUrl("/reporting/advanced-filtering-insights"),
            beta: true,
            hidden:
              !flags.enableAdvancedFilteringInsights ||
              !session.activeOrganization?.advancedFilteringEnabled,
          },
          {
            key: "appaware",
            label: "AppAware",
            path: createUrl("/reporting/appaware"),
            hidden: this.props.hideAppAware,
          },
          {
            key: "scheduled-reports",
            label: "Scheduled Reports",
            path: createUrl("/reporting/scheduled-reports"),
            hidden:
              /read_only|policies_only/.test(
                session.user?.rolesByOrganization[currentOrg.id],
              ) || localStorage.getItem("organization.isMSPSubAccount"),
          },
          {
            key: "data-explorer",
            label: "Data Explorer",
            path: createUrl("/reporting/data-explorer"),
            hidden: hideLegacyReports,
          },
          {
            key: "total-request",
            label: "Total Requests",
            path: createUrl("/reporting/total-requests"),
            hidden: hideLegacyReports,
          },
          {
            key: "queries-per-second",
            label: "Queries Per Second",
            path: createUrl("/reporting/queries-per-second"),
            hidden: hideLegacyReports,
          },
          ...(!localStorage.getItem("owned_msp_id")
            ? [
                {
                  key: "most-active-sites",
                  label: "Most Active Sites",
                  path: createUrl("/reporting/most-active-sites"),
                  hidden: hideLegacyReports,
                },
              ]
            : [
                {
                  key: "most-active-organizations",
                  label: "Most Active Orgs",
                  path: createUrl("/reporting/most-active-organizations"),
                  hidden: hideLegacyReports,
                },
              ]),
          {
            key: "top-requests",
            label: "Top Requests",
            path: createUrl("/reporting/top-requests"),
            hidden: hideLegacyReports,
          },
          {
            key: "threats",
            label: "Threats",
            path: createUrl("/reporting/threats"),
            hidden: hideLegacyReports,
          },
          {
            key: "alerts",
            label: "Alerts",
            path: createUrl("/reporting/alerts"),
            hidden:
              hideLegacyReports || mainOrganization.type === "suborganization",
          },
        ],
      },
      {
        key: "settings",
        label: localStorage.getItem("owned_msp_id") ? "MSP" : "Organization",
        path: createUrl("/settings/profile"),
        icon: (
          <MdOutlineBusiness
            className={cn(
              "site-menu-icon",
              Sidebar.getClassNameForMenuItemLinkIcon(),
            )}
          />
        ),
        children: [
          {
            key: "profile",
            label: "Profile",
            path: createUrl("/settings/profile"),
          },
          {
            key: "users",
            label: "Users",
            path: createUrl("/settings/users"),
          },
          {
            key: "whitelabel",
            label: "Whitelabel",
            path: createUrl("/settings/whitelabel"),
            hidden: !localStorage.getItem("organization.isMSP"),
          },
          {
            key: "subscriptions",
            label: "Subscriptions",
            path: createUrl("/settings/subscriptions"),
            hidden: !showSubscriptionsPage,
          },
          {
            key: "billing-info",
            label: "Billing",
            path: createUrl("/settings/billing-info"),
            hidden:
              !localStorage.getItem("organization.showBilling") ||
              localStorage.getItem("organization.isMSPSubAccount") ||
              localStorage.getItem("organization.readOnly"),
          },
          {
            key: "psa-management",
            label: "PSA Management",
            path: `/psa-integration`,
            hidden: !this.props.showPsaIntegration,
            externalLink: true,
          },
          {
            key: "settings",
            label: "Settings",
            path: createUrl("/settings/settings"),
          },
        ],
      },
      {
        key: "tools",
        label: "Tools",
        path: createUrl("/tools/ssl-certificate"),
        icon: (
          <MdOutlineBuild
            className={cn(
              "site-menu-icon",
              Sidebar.getClassNameForMenuItemLinkIcon(),
            )}
          />
        ),
        children: [
          {
            key: "ssl-certificate",
            label: "SSL Certificate",
            path: createUrl("/tools/ssl-certificate"),
          },
          ...(!localStorage.getItem("owned_msp_id")
            ? [
                {
                  key: "query-log",
                  label: "Query Log",
                  path: createUrl("/tools/query-log"),
                  hidden: !flags.showLegacyQueryLogUi,
                },
                {
                  key: "query-log-new",
                  label: "DNS Query Log",
                  path: createUrl("/tools/dns-query-log"),
                  hidden: !flags.showNewQueryLogUi,
                },
                {
                  key: "advanced-filtering-log",
                  label: "Adv. Filtering Log",
                  path: createUrl("/tools/advanced-filtering-log"),
                  hidden: !showAdvFilteringLog,
                },
                {
                  key: "policy-audit-log",
                  label: "Policy Audit Log",
                  path: createUrl("/tools/policy-audit-log"),
                  hidden: session.user.plan === "basic",
                },
              ]
            : []),
          ...(showDataExport
            ? [
                {
                  key: "data-export",
                  label: "Data Export",
                  path: createUrl("/tools/data-export"),
                },
              ]
            : []),
          {
            key: "domain-lookup",
            label: "Domain Report",
            path: createUrl("/tools/domain-lookup"),
          },
        ],
      },
      {
        key: "integrations",
        label: "Integrations",
        path: createUrl("/integrations"),
        icon: (
          <MdMiscellaneousServices
            className={cn(
              "site-menu-icon",
              Sidebar.getClassNameForMenuItemLinkIcon(),
            )}
          />
        ),
        hidden:
          !session.user.rolesByOrganization[mainOrganization.id].match(
            /owner|administrator/,
          ) ||
          (localStorage.getItem("whitelabel.name") &&
            mainOrganization.type === "suborganization"),
      },
    ]
  }

  isMenuItemActive(key) {
    const urlKeySegment = this.props.location.pathname.split("/")[3]
    return this.getMenuItems().some(
      (item) => item.key === key && item.key === urlKeySegment,
    )
  }

  isSubmenuItemActive(path) {
    return this.props.location.pathname.startsWith(path)
  }

  onSubOrgBillingInfoClick = () => {
    const { currentOrg } = this.props
    localStorage.setItem("owned_msp_id", currentOrg.owned_msp_id)
    localStorage.setItem("orgId", currentOrg.id)
  }

  renderTrialDaysBanner = ({ currentOrg, isLeft = true }) => {
    if (localStorage.getItem("orgChanged")) {
      return null
    }

    if (!isLeft) {
      return null
    }

    let message = `${currentOrg.trial_days} ${
      currentOrg.trial_days === 1 ? "day" : "days"
    } left in trial`

    let distributorLenovo = currentOrg?.stated_distributor === "lenovo" ?? false

    if (distributorLenovo && this.props.subscriptions.data?.data.length) {
      message = `Your subscription starts in ${currentOrg.trial_days} ${
        currentOrg.trial_days === 1 ? "day" : "days"
      }`
    }

    return <p className={Sidebar.getClassNameForTrialDaysBanner()}>{message}</p>
  }

  renderFeatureTrialDaysBanner = (featureTrials) => {
    if (
      localStorage.getItem("orgChanged") ||
      featureTrials.every((trial) => !trial.active)
    ) {
      return null
    }
    return (
      <p
        className={`${Sidebar.getClassNameForTrialDaysBanner()} flex justify-between`}
      >
        Active Feature Trial{" "}
        <FeatureTrialPopover featureTrials={featureTrials} />
      </p>
    )
  }

  render() {
    const { currentOrg, subscriptions, entitlements } = this.props
    let distributorLenovo = currentOrg?.stated_distributor === "lenovo" ?? false

    const statusInTrial =
      subscriptions?.data?.data[0]?.attributes?.status === "in_trial"

    const cancelDateHasExpired =
      moment(currentOrg?.will_cancel_at).diff(moment(), "days") > 0

    return (
      <div className="hidden-print">
        <div
          className={cn("site-menubar", Sidebar.getClassNameForMenuBar())}
          style={{ top: NAVBAR_HEIGHT }}
        >
          <ul className="site-menu">
            {this.getMenuItems()
              .filter((item) => !item.hidden)
              .map((item) => (
                <li
                  key={item.key}
                  className={cn(
                    "site-menu-item",
                    this.isMenuItemActive(item.key) && "active open",
                    Sidebar.getClassNameForMenuItem(),
                  )}
                >
                  <Link
                    className={Sidebar.getClassNameForMenuItemLink()}
                    to={{
                      pathname: item.path,
                      search: item.path.includes("reporting")
                        ? this.props.location.search
                        : null,
                    }}
                    data-test-id={`site-menu-${item.key}`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item.icon}
                    <span className="site-menu-title">{item.label}</span>
                  </Link>
                  {item.children && (
                    <ul
                      className={cn(
                        "site-menu site-menu-sub animated",
                        this.isMenuItemActive(item.key) &&
                          Sidebar.getClassNameForActiveSubmenu(),
                      )}
                      style={{
                        height: this.isMenuItemActive(item.key)
                          ? Sidebar.SUBMENU_ITEM_FULL_HEIGHT *
                            item.children.filter((child) => !child.hidden)
                              .length
                          : 0,
                      }}
                    >
                      {item.children.map((child) =>
                        child.hidden ? null : (
                          <li
                            key={child.path}
                            className={cn(
                              "site-menu-item",
                              this.isSubmenuItemActive(child.path) && "active",
                              Sidebar.getClassNameForSubmenuItem(),
                            )}
                          >
                            {currentOrg.owned_msp_id &&
                            child.path.includes("organization") &&
                            child.key === "billing-info" ? (
                              <a
                                className={Sidebar.getClassNameForSubmenuItemLink()}
                                data-test-id={`site-menu-subitem-${child.key}`}
                                href={`/msp/${currentOrg.owned_msp_id}/settings/billing-info`}
                                onClick={this.onSubOrgBillingInfoClick}
                              >
                                {child.label}
                              </a>
                            ) : child.externalLink ? (
                              <button
                                className={Sidebar.getClassNameForSubmenuItemLink()}
                                onClick={() => {
                                  this.props.setIsPsaIntegrationClicked(true)
                                }}
                              >
                                {child.label}
                                <MdOutlineOpenInNew className="relative left-5 h-5 w-5" />
                              </button>
                            ) : (
                              <Link
                                className={Sidebar.getClassNameForSubmenuItemLink()}
                                data-test-id={`site-menu-subitem-${child.key}`}
                                to={{
                                  pathname: child.path,
                                  search: item.path.includes("reporting")
                                    ? this.props.location.search
                                    : null,
                                }}
                              >
                                <span className="flex items-center space-x-2 whitespace-normal">
                                  <span className="leading-normal">
                                    {child.label}
                                  </span>
                                  {child.beta && (
                                    <Badge
                                      className={`ml-3`}
                                      type={`mui`}
                                      text={`beta`}
                                    />
                                  )}
                                </span>
                              </Link>
                            )}
                          </li>
                        ),
                      )}
                    </ul>
                  )}
                </li>
              ))}
          </ul>
          <ul className={Sidebar.getClassNameForWidgetList()}>
            {!!currentOrg &&
              !localStorage.getItem("organization.isMSPSubAccount") &&
              localStorage.getItem("organization.showBilling") &&
              !!currentOrg.trial_days && (
                <>
                  <li className={Sidebar.getClassNameForWidgetListItem()}>
                    {!!currentOrg.trial_days &&
                      (subscriptions?.success
                        ? !localStorage.getItem("user.isMSP") &&
                          !localStorage.getItem(
                            "organization.isMSPSubAccount",
                          ) &&
                          subscriptions?.data?.data[0]?.attributes?.status ===
                            "active"
                          ? this.renderTrialDaysBanner({
                              currentOrg,
                              isLeft: false,
                            })
                          : localStorage.getItem("user.isMSP") &&
                              !localStorage.getItem(
                                "organization.isMSPSubAccount",
                              ) &&
                              !statusInTrial &&
                              !cancelDateHasExpired
                            ? this.renderTrialDaysBanner({
                                currentOrg,
                                isLeft: false,
                              })
                            : this.renderTrialDaysBanner({
                                currentOrg,
                              })
                        : subscriptions?.failure
                          ? this.renderTrialDaysBanner({
                              currentOrg,
                            })
                          : null)}
                    {!localStorage.getItem("user.isMSP") &&
                      !localStorage.getItem("organization.isMSPSubAccount") &&
                      currentOrg.update_payment &&
                      !subscriptions?.fetching &&
                      subscriptions?.data?.data[0]?.attributes?.status !==
                        "active" &&
                      !distributorLenovo && (
                        <p
                          className={Sidebar.getClassNameForSelectSubscriptionCaption()}
                        >
                          <Link
                            to={createUrl(
                              "/settings/billing-info/subscription",
                            )}
                          >
                            <strong>Select a subscription</strong>
                          </Link>{" "}
                          to avoid interruption of service
                        </p>
                      )}
                  </li>
                  <PartnerProgramWidget
                    showPartnerProgramPage={this.props.showPartnerProgramPage}
                  />
                </>
              )}
            {entitlements?.data?.data[0]?.attributes?.trials?.length > 0 &&
              this.renderFeatureTrialDaysBanner(
                entitlements?.data?.data[0]?.attributes?.trials,
              )}
            {this.props.showPartnerPortal && (
              <li className={Sidebar.getClassNameForWidgetListItem()}>
                <Tooltip
                  content="Partner Portal login credentials match https://app.dnsfilter.com"
                  placement="top-end"
                >
                  <Transition
                    as="span"
                    enter="transition-opacity duration-500"
                    enterFrom="opacity-30"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-30"
                    appear
                    show
                  >
                    <a
                      className={Sidebar.getClassNameForAccessPartnerPortalButton()}
                      href="https://partners.dnsfilter.com/wp-login.php"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        window.trackAnalyticsEvent("Partner Portal Click", {
                          user_id: localStorage.getItem("userId"),
                          organization_id: localStorage.getItem("orgId"),
                          msp_id: this.props.mspId,
                        })
                      }}
                    >
                      <span
                        className={Sidebar.getClassNameForAccessPartnerPortalButtonText()}
                      >
                        <div className="flex items-center">
                          <div className="mr-3">
                            <PartnerPortalIcon />
                          </div>
                          <div className="text-left">Access Partner Portal</div>
                        </div>
                      </span>
                    </a>
                  </Transition>
                </Tooltip>
              </li>
            )}
          </ul>
        </div>
      </div>
    )
  }
}

function NavigationContainer(props) {
  const platform = useDistributorPlatform()

  const session = useContext(SessionContext)
  const mainOrganization = session.getMainOrganization()

  const [isPsaIntegrationClicked, setIsPsaIntegrationClicked] = useState(false)

  const flags = useFlags()

  const existingProvidersQuery = useQuery(
    "/auth_identities/existing_providers",
    () =>
      httpClient
        .get("/auth_identities/existing_providers")
        .then((response) => response.data),
  )
  const isMsp = !!localStorage.getItem("user.isMSP")
  const ssoEnabled = session?.activeOrganization?.ssoEnabled
  const mspId = session?.msp?.id
  const mainOrgId = session.getMainOrganization()?.id
  const currentOrgId = session?.activeOrganization?.id
  const roleInCurrentOrg =
    currentOrgId && session?.user?.rolesByOrganization[currentOrgId]
  const showPartnerPortal =
    flags.showPartnerPortalButton &&
    isMsp &&
    (mainOrgId === currentOrgId ||
      isPageMspDashboard(
        session?.activeOrganization,
        window.location.pathname,
      )) &&
    !ssoEnabled &&
    (roleInCurrentOrg === "owner" || roleInCurrentOrg === "administrator") &&
    existingProvidersQuery.data?.auth0

  const hideAppAware = hideAppAwarePage({
    showAppAwarePlatform: platform.showAppAware,
    session,
  })

  let subscriptionRes = useResource({
    initialParams: {},
    async fetch() {
      try {
        let res = await httpClient.get("/subscriptions", {
          params: {
            organization_id: localStorage.getItem("orgId"),
          },
        })

        return {
          data: res.data,
          meta: {},
        }
      } catch (error) {
        throw error.response
      }
    },
  })

  let entitlementsRes = useResource({
    initialParams: {},
    async fetch() {
      try {
        let res = await httpClient.get("/entitlements", {
          params: {
            organization_id: localStorage.getItem("orgId"),
          },
        })
        return {
          data: res.data,
          meta: res.meta,
        }
      } catch (error) {
        throw error.response
      }
    },
  })

  const showPsaIntegration =
    flags.showPsaIntegration &&
    (localStorage.getItem("organization.isMSP") ||
      localStorage.getItem("organization.isMSPSubAccount")) &&
    mainOrganization?.activePsaIntegration &&
    !/read_only|policies_only/.test(
      session.user?.rolesByOrganization[mainOrgId],
    )

  useQuery(
    "/psa_integration/redirect_link",
    () =>
      httpClient
        .get("/psa_integrations/redirect_link", {
          params: {
            organization_id: mainOrgId,
          },
        })
        .then((res) => {
          window.open(res.data.redirect_link, "_blank")?.focus()
          setIsPsaIntegrationClicked(false)
        }),
    {
      enabled: !!showPsaIntegration && !!isPsaIntegrationClicked,
    },
  )

  const { subscriptions } = props

  useEffect(() => {
    if (subscriptions.type === UPDATE_SUBSCRIPTIONS) {
      subscriptionRes.fetch()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptions])

  if (!platform.showNavigation) {
    return null
  }

  return (
    <Navigation
      {...props}
      subscriptions={subscriptionRes}
      entitlements={entitlementsRes}
      showActiveDirectory={mainOrganization?.showActiveDirectory ?? false}
      session={session}
      mainOrganization={mainOrganization}
      hideAppAware={hideAppAware}
      showPartnerPortal={showPartnerPortal}
      mspId={mspId}
      flags={flags}
      showPsaIntegration={showPsaIntegration}
      setIsPsaIntegrationClicked={setIsPsaIntegrationClicked}
    />
  )
}

const mapStateToProps = ({ auth, organizations, subscriptions }) => ({
  currentOrg: get(organizations, "data", []).find(
    (org) => Number(org.id) === Number(localStorage.getItem("orgId")),
  ),
  subscriptions,
  showPartnerProgramPage: !hidePartnerProgramPage({
    auth,
  }),
})

Navigation.propTypes = {
  location: locationPropTypes.isRequired,
  currentOrg: organizationPropTypes.isRequired,
}

export default withRouter(connect(mapStateToProps, null)(NavigationContainer))
